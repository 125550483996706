const Consulting = [
    {
        description:
            'Strategic Consulting in Product Solution Design, Product Development, Implementation & Performance Engineering.'
    },
    {
        description1:'Experienced Leaders Across Various Domains In Ensuring Delivery of Efficiencies With Global Team Availability.'
    }
];

const oms = [
    {
        title: 'OMS/ADROIT',
        paragraph:
            'Implement Buy Online Pickup in Store (BOPIS) for HBC - OFF5 Brand. '
    }
];
const services = [
    {
        data: 'Solution Definition and Configuration'
    },
    {
        data: 'Detailed Designs and Proof of Concepts'
    },
    {
        data: 'Go-Live Support and Maintenance'
    },
    {
        data: 'Performance Engineering of BOPIS Volumes'
    },
    {
        data: 'Development and Quality Engineering'
    },
    {
        data: 'Enhanced Fulfillment cycles & Inventory visibility'
    }
];
const impact = [
    {
        title: 'Impact'
    },
    {
        description:
            'Enabling Global Retailers to Allow their Customers across the USA, to Place Orders Online and Pickup the Orders at a Convenient Store, within a few Hours.'
    },
    {
        description1:`Enabled One of the World's Largest Apparel Retailers to Allow their Customers across the USA, to Place Orders Online and Pickup the Orders at a Convenient Store, within a few Hours.`
    },
    {
        descriptiondata:
            'Bringing in Advantages of the Latest Feature Sets, while Bringing in Significant Savings on Infrastructure & Easy Maintenance.'
    },
    // {
    //     descriptiondata1:`Enabled Customer to move their Data from Legacy System to Cloud Environment. This helps with the Advantage of using the Latest Feature, while Bringing in Significant Savings on Heavy Infrastructure Maintenance Cost.`
    // }
];

const Adropt = [
    {
        title: 'OMS/ADROIT'
    },
    {
        description:
            'Migration to IBM OMoC of a Global Retailer from Multi-Instance On Premises Legacy Data - to Single Instance On-Cloud Environment.'
    }
];

const servicesFeatured = [
    {
        data: 'Solution Definition and Configuration'
    },
    {
        data: 'Detailed Designs and Proof of Concepts'
    },
    {
        data: 'Development for Data Migration'
    },
    {
        data: 'Refactoring Code to Support OMS Latest Version'
    },
    {
        data: 'Performance Tuning for Peak Volumes'
    },
    {
        data: 'Go-Live Support And Maintenance'
    }
];

module.exports = {
    Consulting,
    oms,
    servicesFeatured,
    Adropt,
    impact,
    services
};
